import React, { useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  HashRouter,
} from "react-router-dom";

import { PageTransition } from "@steveeeie/react-page-transition";

// import "./App.css";
import "./global.css";

import Routes from "./Routes";

function App() {
  return (
    <HashRouter>
      <Routes />
    </HashRouter>
  );
}

export default App;
