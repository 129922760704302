import React from "react";
import { Card } from "react-bootstrap";
import "./LoginUser/style.css";

const SelectDriver = () => {
  return (
    <div className="App">
      <div className="chatWithUser">
        <div className="mobContainer">
          <Card>
            <div style={{ height: "20vh" }}></div>
            {/* <div className="headingText"> Chat With Username</div> */}
            <div className="border" style={{ height: "40vh" }}>
              <h3 className="fw-300 text-9 mb-5 p-5 text-center">
                Please select driver
              </h3>
            </div>
          </Card>
        </div>
        <div className="copyRight">Copyright © 2022 Alita Infotech</div>
      </div>
    </div>
  );
};

export default SelectDriver;
