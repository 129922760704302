import "./style.css";
import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { Card } from "react-bootstrap";

function FirstName({ next, data }) {
  const [loader, setLoader] = useState(false);

  const validation = Yup.object().shape({
    first_name: Yup.string()
      .required("Firstname is required.")
      .matches(/^[A-Za-z]+$/, "Only alphabets allowed."),
  });

  const submitForm = (values, { setStatus, resetForm }) => {
    setLoader(true);

    axios
      .post("minimal-signup", {
        first_name: values?.first_name,
        phone_code: data?.phone_code,
        phone_number: data?.phone_number,
        otp_token: data?.otp_token,
      })
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          resetForm();
          if (res?.data?.data?.access_token) {
            const access_token = "Bearer " + res?.data?.data?.access_token;
            localStorage.setItem("access_token", access_token);
            axios.defaults.headers.common["Authorization"] = access_token;
          }
          if (res?.data?.data?.user) {
            const user = res?.data?.data?.user;
            localStorage.setItem("user", JSON.stringify(user));
          }
          //   history.push("/chat-screen");
          next(values, true);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response) {
          if (err?.response?.data?.message) {
            setStatus({
              error: Array.isArray(err?.response?.data?.message)
                ? err?.response?.data?.message[0]
                : err?.response?.data?.message,
            });
          }
        } else {
          setStatus({ error: "Couldn't connect to server" });
        }
      });
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={validation}
      onSubmit={(values, { setErrors, setStatus, resetForm }) => {
        submitForm(values, { setErrors, setStatus, resetForm });
      }}
    >
      {(formik) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setStatus,
          status,
        } = formik;
        return (
          <div className="App">
            <div className="chatWithUser">
              <div className="mobContainer">
                <Card>
                  <div style={{ height: "20vh" }}></div>
                  {/* <div className="headingText"> Chat With Username</div> */}
                  <h3 className="fw-300 text-9 mb-5">Chat With Username</h3>
                  <form id="mobileForm" onSubmit={handleSubmit}>
                    <label htmlFor="first_name" className="form-label fw-300">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control bg-light border-light input"
                      id="first_name"
                      placeholder="Enter Your First Name"
                      name="first_name"
                      onChange={(e) => {
                        handleChange(e);
                        setStatus({ error: "" });
                      }}
                      value={values.first_name}
                    />
                    <span className="text-danger">
                      {(touched.first_name && errors.first_name) ||
                        (status &&
                          status.error &&
                          (errors.first_name || status.error))}
                    </span>

                    <div className="d-grid my-4">
                      <button
                        type="submit"
                        className="btn btn-dark shadow-none fw-400 button"
                        disabled={loader}
                      >
                        <span>Next</span>
                        {loader && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>
                  </form>
                </Card>
              </div>
              <div className="copyRight">Copyright © 2022 Alita Infotech</div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default FirstName;
