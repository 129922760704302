import "./style.css";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { faUser as userReg } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";
import profilePic from "../../images/zoe.svg";
import axios from "axios";

function Profile(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [driver, setDriver] = useState({});
  const slug = props?.match?.params?.slug;

  const [httpStatus, setHttpStatus] = useState();

  const access_token = localStorage.getItem("access_token");

  const submitOperation = (e) => {
    if (driver) {
      localStorage.setItem("driver", JSON.stringify(driver));
    }
    if (access_token !== null) {
      setTimeout(() => {
        history.push("/offer/new");
      }, 800);
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    setLoader(true);
    axios
      .get("driver/" + slug)
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          setDriver(res?.data?.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err?.response?.status);
        setHttpStatus(err?.response?.status);
      });
  };

  return (
    <div className="App">
      <div className="profileScreen">
        {loader ? (
          <div className="mt-5">
            <span
              className="spinner-border text-primary"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        ) : Object.keys(driver).length > 0 ? (
          <div className="mobContainer">
            <Card>
              <div className="profileSetting">
                <div className="imageDisplay">
                  <img src={profilePic} className="imageSource" />
                </div>
              </div>
              <div className="onlinText">Online</div>
              <h3 className="fw-300 text-9">
                {driver?.first_name + " " + driver?.last_name}
              </h3>

              <div className="detailText mb-5">Details</div>
              <div className="userDetails">
                <div className="detailList">
                  <span className="iconImages">
                    <FontAwesomeIcon
                      className="iconProfileHight"
                      icon={faStar}
                    />
                  </span>
                  <span>4.95</span>
                </div>
                <div className="detailList">
                  <span className="iconImages">
                    <FontAwesomeIcon
                      className="iconProfileHight "
                      icon={userReg}
                    />
                  </span>
                  <span>
                    <b>Bio:-</b> {driver?.bio}
                  </span>
                </div>
                <div className="detailList">
                  <span className="iconImages">
                    <FontAwesomeIcon
                      className="iconProfileHight"
                      icon={faLanguage}
                    />
                  </span>
                  <span>Knows English, Spanish, French</span>
                </div>
                <div className="detailList">
                  <span className="iconImages">
                    <FontAwesomeIcon
                      className="iconProfileHight"
                      icon={userReg}
                    />
                  </span>
                  <span>Interests</span>
                </div>
              </div>

              <button
                className="btn btn-dark shadow-none fw-400 button "
                type="submit"
                onClick={(event) => submitOperation(event)}
              >
                <span>Chat</span>
                {loader && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </Card>
          </div>
        ) : (
          <h3 className="fw-300 text-9 mt-5">
            {httpStatus ? httpStatus : "No record found"}
          </h3>
        )}
      </div>
    </div>
  );
}

export default withRouter(Profile);
