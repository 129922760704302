import React, { useState } from "react";
import { useHistory } from "react-router";
import Select, { components } from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import useDebouncedEffect from "use-debounced-effect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faLocationDot,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import profilePic from "../../images/eliot.svg";

import reactSelectStyle from "./reactSelectStyle";

import {
  Avatar,
  Button as UiButton,
  ChatContainer,
  ConversationHeader,
  MessageList,
} from "@chatscope/chat-ui-kit-react";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faLocationDot} size="lg" />
    </components.DropdownIndicator>
  );
};

const CreateTrip = ({
  setIsAddNewTrip,
  tripId,
  chatContainerStyle,
  handleBackClick,
  loginUser,
  logOut,
  setTripId,
  driverId,
  setCurrentTrip,
  setNewTrip,
  driver,
  setCurrentScreenMsg,
}) => {
  const [loader, setLoader] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [locationData, setLocationData] = useState({
    pickup_lat: 0,
    pickup_long: 0,
    drop_lat: 0,
    drop_long: 0,
  });

  const [listOption, setListOption] = useState([]);
  const [placeText, setPlaceText] = useState("");

  const history = useHistory();

  const initialValues = {
    pickup: "",
    drop: "",
    offer_amount: "",
    message: "",
    sms: false,
  };
  const validation = Yup.object().shape({
    // pickup: Yup.string().required("Please select pickup location."),
    // drop: Yup.string().required("Please select drop location."),
    // offer_amount: Yup.string().required("Offer amount is required."),
    message: Yup.string().required("Message is required."),
  });
  const submitOperation = (values, { setStatus, resetForm }) => {
    let tripResponse = null;
    setLoader(true);
    axios
      .post(`trips/${Boolean(tripId) ? "update" : "create"}`, {
        destination_lat: values?.drop?.latitude,
        destination_long: values?.drop?.longitude,
        location_name:
          values?.pickup?.place_name + " - " + values?.drop?.place_name,
        pick_up_lat: values?.pickup?.latitude,
        pick_up_long: values?.pickup?.longitude,
        trip_id: tripId || "",
      })
      .then((res) => {
        tripResponse = res?.data?.data;
        setCurrentTrip(res?.data?.data?.location_name);
        let offer_amount = values?.offer_amount || 0;
        axios
          .post("offers/send", {
            is_broadcast: 0,
            is_question: offer_amount === 0 ? 1 : 0,
            message: values?.message,
            offer_amount: offer_amount,
            payment_type: "cash",
            receiver_id: driverId,
            trip_id: res?.data?.data?.id,
          })
          .then((res) => {
            setLoader(false);
            setSnackbar(true);
            setIsAddNewTrip(false);
            setNewTrip(true);
            console.log("CREATE:: ", res?.data?.data);
            setCurrentScreenMsg(res?.data?.data, values?.message);
            history.push(`/offer/new/` + res?.data?.data?.offer?.id);
          })
          .catch((err) => {
            setLoader(false);
            console.log(err?.response?.status);
            if (err?.response?.data?.message) {
              alert(
                Array.isArray(err?.response?.data?.message)
                  ? err?.response?.data?.message[0]
                  : err?.response?.data?.message
              );
            }
          });
      })
      .catch((err) => {
        setLoader(false);
        console.log(err?.response?.status);
        if (err?.response?.data?.message) {
          alert(
            Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message
          );
        }
      });
  };

  const onInputChange = (e) => {
    setPlaceText(e);
  };

  useDebouncedEffect(
    () => {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${placeText}.json?access_token=pk.eyJ1IjoiYWJ1YmFrYXJjb2RlcyIsImEiOiJjbDI3dGNxa20wMnNyM2VsZzhoOGZieG5lIn0.y4HniWvmZ-I9AqaqSJqxMA`
        )
        .then((res) => {
          if (res?.status === 200) {
            const list = res?.data?.features?.map((data) => {
              const value = {
                latitude: data?.geometry?.coordinates[0],
                longitude: data?.geometry?.coordinates[1],
                place_name: data?.place_name,
              };
              return { label: data?.place_name, value };
            });
            setListOption(list);
          } else {
            alert("Failed to get location");
          }
        })
        .catch((err) => {
          console.log(err?.response?.status);
        });
    },
    100,
    [placeText]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={(values, { setStatus, resetForm }) => {
        submitOperation(values, { setStatus, resetForm });
      }}
    >
      {(formik) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        } = formik;
        return (
          <ChatContainer style={chatContainerStyle}>
            {snackbar && (
              <div class="alert alert-primary" role="alert">
                Offer sent successfully.
              </div>
            )}
            <ConversationHeader className="display_hide_menu">
              <ConversationHeader.Back onClick={handleBackClick} />

              <Avatar src={profilePic} size="md" />
              <ConversationHeader.Content>
                {loginUser}
              </ConversationHeader.Content>
              <ConversationHeader.Actions>
                <div>
                  <UiButton
                    onClick={(e) => logOut(e)}
                    icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                  />
                </div>
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList>
              <MessageList.Content>
                <div className="chatWithUser">
                  <div className="new_trip_data">
                    <div className="headingText">
                      Send a New Offer to{" "}
                      {driver?.first_name + " " + driver?.last_name}
                    </div>
                    <form
                      className="formData"
                      id="submitData"
                      onSubmit={handleSubmit}
                    >
                      <Select
                        className="basic-single mb-2"
                        styles={reactSelectStyle}
                        placeholder="Search pick up location"
                        name="pickup"
                        options={listOption}
                        onInputChange={onInputChange}
                        onChange={(value) =>
                          setFieldValue("pickup", value?.value)
                        }
                        noOptionsMessage={() => "Please enter pick up location"}
                        // defaultValue={colourOptions[0]}
                        // isDisabled={isDisabled}
                        // isLoading={isLoading}
                        isClearable
                        // isRtl={isRtl}
                        // isSearchable={isSearchable}
                        components={{
                          Placeholder,
                          DropdownIndicator,
                        }}
                      />
                      <span className="text-danger">
                        {touched.pickup && errors.pickup && errors.pickup}
                      </span>
                      <Select
                        className="basic-single mb-2"
                        styles={reactSelectStyle}
                        placeholder="Search drop location"
                        name="drop"
                        onInputChange={onInputChange}
                        options={listOption}
                        noOptionsMessage={() => "Please enter drop location"}
                        onChange={(value) =>
                          setFieldValue("drop", value?.value)
                        }
                        // defaultValue={colourOptions[0]}
                        // isDisabled={isDisabled}
                        // isLoading={isLoading}
                        isClearable
                        // isRtl={isRtl}
                        // isSearchable={isSearchable}
                        components={{
                          Placeholder,
                          DropdownIndicator,
                        }}
                      />
                      <span className="text-danger">
                        {touched.drop && errors.drop && errors.drop}
                      </span>
                      <input
                        type="number"
                        className="form-control border-dark input mb-2"
                        placeholder="Offer amount"
                        id="offer_amount"
                        name="offer_amount"
                        value={values.offer_amount}
                        onChange={handleChange}
                      />
                      <span className="text-danger">
                        {touched.offer_amount &&
                          errors.offer_amount &&
                          errors.offer_amount}
                      </span>

                      <textarea
                        type="text"
                        rows={3}
                        className="form-control border-dark input mb-2"
                        placeholder="Message"
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      <span className="text-danger">
                        {touched.message && errors.message && errors.message}
                      </span>
                      {/* <div className="bg-light p-4 rounded my-3 d-flex justify-content-between align-items-center ">
                        <div className="d-flex">
                          <div className="py-2 px-3 rounded h-25 lock-div">
                            <FontAwesomeIcon icon={faLock} color="#a0b3ce" />
                          </div>
                          <label
                            className="form-check-label ms-3 "
                            htmlFor="sms"
                          >
                            <b className="text-muted">SMS Updates</b>
                            <div className="send-me">Send me SMS Updates</div>
                          </label>
                        </div>
                        <div className="form-check form-switch ">
                          <input
                            className="form-check-input  "
                            type="checkbox"
                            id="sms"
                            name="sms"
                            value={values.sms}
                            onChange={(e) => {
                              setFieldValue("sms", e.target.checked);
                            }}
                          />
                        </div>
                      </div> */}

                      <button
                        className="btn btn-dark shadow-none fw-400 button w-100"
                        type="submit"
                        disabled={loader}
                        // onClick={(event) => submitOperation(event)}
                      >
                        <span>Send Offer</span>
                        {loader && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </MessageList.Content>
            </MessageList>
          </ChatContainer>
        );
      }}
    </Formik>
  );
};

export default CreateTrip;
