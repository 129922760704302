import React, { useEffect } from "react";
import axios from "axios";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

// import "./App.css";
import "./global.css";

import LoginUser from "./components/LoginUser/LoginUser";
import ChatScreen from "./components/ChatScreen/ChatScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import Profile from "./components/Profile/Profile";
import SelectDriver from "./components/SelectDriver";

function Routes() {
  const history = useHistory();
  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "driver" && e.oldValue && !e.newValue) {
        history.push("/");
      } else if (e.key === "driver" && e.newValue) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  const access_token = localStorage.getItem("access_token");
  const driver = localStorage.getItem("driver")
    ? JSON.parse(localStorage.getItem("driver"))
    : null;
  console.log("driver", driver);
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = access_token;
  }

  // axios.defaults.headers.common["Authorization"] =
  //   "Bearer 33|nDkkqCovV4f1xYyvr6GLGVLs8k0i2eUS9WSgZ01M";
  return (
    <Route
      render={({ location }) => {
        return (
          // <PageTransition
          //   preset="moveToLeftFromRight"
          //   transitionKey={location.pathname}
          // >
          <Switch location={location}>
            <Route
              render={() => {
                const access_token = localStorage.getItem("access_token");
                const driver = localStorage.getItem("driver")
                  ? JSON.parse(localStorage.getItem("driver"))
                  : null;
                return Boolean(access_token && driver) ? (
                  <Redirect to="/offer/new" />
                ) : !Boolean(driver) ? (
                  <Redirect to="/select-driver" />
                ) : (
                  <LoginUser />
                );
              }}
              path="/"
              exact
              // component={LoginUser}
            />
            <Route path="/select-driver" exact component={SelectDriver} />
            {/* <ProtectedRoute
              path="/chat-screen"
              exact
              component={
                Boolean(localStorage.getItem("driver")) ? ChatScreen : null
              }
            /> */}
            <ProtectedRoute
              path="/offer/:status/:slug?"
              exact
              component={
                Boolean(localStorage.getItem("driver")) ? ChatScreen : null
              }
            />
            {/* <Route path="/offer/:slug" component={ChatScreen} /> */}
            <Route path="/:slug" component={Profile} />
            <Redirect to="/" />
          </Switch>
          // </PageTransition>
        );
      }}
    />
  );
}

export default Routes;
