import React, { useState } from "react";
import MobileForm from "./MobileForm";
import CodeVerify from "./CodeVerify";
import FirstName from "./FirstName";
import { useHistory } from "react-router";

const LoginUser = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();

  const [data, setData] = useState({
    phone_code: "",
    phone_number: "",
    otp: "",
    first_name: "",
  });

  // console.log(data);

  const submitForm = (newData) => {
    console.log("form-data", newData);
    const candidateName = newData?.phone_code;
    const phone = newData?.phone_number;
    history.push({
      pathname: "/offer/new",
      state: { candidateName, phone },
    });
    // alert(newData);
  };

  const handleNextStep = (newData, finalStep = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (finalStep) {
      submitForm(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };
  const steps = [
    <MobileForm next={handleNextStep} data={data} />,
    <CodeVerify next={handleNextStep} data={data} />,
    <FirstName next={handleNextStep} data={data} />,
  ];

  return <>{steps[currentStep]}</>;
};

export default LoginUser;
