import "../../App.css";
import "./style.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import moment from "moment";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

import profilePic from "../../images/eliot.svg";
import Car from "../../images/car.png";
import { Button } from "react-bootstrap";

import {
  Avatar,
  Button as UiButton,
  ChatContainer,
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
  SendButton,
  Sidebar,
} from "@chatscope/chat-ui-kit-react";

import { detectMob } from "../../utils";
import CreateTrip from "./CreateTrip";
import { OFFER_STATUS } from "../../constant";

function ChatDesktop(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const driver = JSON.parse(localStorage.getItem("driver") || "{}");
  const tabList = [
    { label: "New", value: "new" },
    { label: "Accepted", value: "accepted" },
    { label: "Completed", value: "completed" },
    { label: "Expired", value: "expired" },
  ];
  const [loader, setLoader] = useState(false);
  const [offer_amount, setOffer_amount] = useState("");
  const [offerCountFlag, setOfferCountFlag] = useState(false);
  const [conversationID, setConversationID] = useState("");
  const [cancelLoader, setCancelLoader] = useState("");
  const [conversationLoader, setConversationLoader] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [currentTrip, setCurrentTrip] = useState({});
  const [tripId, setTripId] = useState({});
  const [newTrip, setNewTrip] = useState(false);
  const [conversation, setConversation] = useState({});
  const [messages, setMessages] = useState([]);
  const [driverProfile, setDriverProfile] = useState("");
  const [driverId, setDriverId] = useState();
  const [receiveMsg, setReceiveMsg] = useState(null);
  const [tripLocation, setTripLocation] = useState("");
  const [chatUserName, setChatUserName] = useState("");
  const [userIsOnline, setUserIsOnline] = useState(Boolean(user?.is_online));
  const [smsStatus, setSmsStatus] = useState(false);

  const [sidebarVisible, setSidebarVisible] = useState(detectMob());
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [loginUser, setLoginUser] = useState("");
  const [fieldData1, setFieldData1] = useState("");
  const [fieldData2, setFieldData2] = useState("");
  const [fieldData3, setFieldData3] = useState("");
  const [fieldData4, setFieldData4] = useState("");
  const [isAddNewTrip, setIsAddNewTrip] = useState(false);
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});

  let slug = props?.match?.params?.slug;
  let status = props?.match?.params?.status;

  // back event for mobile View.
  const handleBackClick = () => {
    setSidebarVisible(!sidebarVisible);
    setChatUserName("");
    // history.push("/offer/new");
    var { from } = location.state || { from: { pathname: "/offer/new" } };
    // console.log("from", location.state);
    history.push(from);
    setConversationID(""); // change here for slug change swap screen 25-07
  };

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let loginUser = user?.first_name || "";
    setLoginUser(loginUser);

    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "100%",
        maxWidth: "100%",
      });

      setConversationContentStyle({
        display: "flex",
      });
      setConversationAvatarStyle({
        marginRight: "1em",
      });
      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarStyle({});
      setChatContainerStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
    }
  }, [
    sidebarVisible,
    setSidebarVisible,
    setSidebarStyle,
    setChatContainerStyle,
    setConversationContentStyle,
    setConversationAvatarStyle,
    setChatList,
  ]);

  const logOut = (e) => {
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("access_token");
    axios
      .post("logout", {})
      .then((r) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        history.push({
          pathname: "/",
        });
      })
      .catch();
  };

  const addNewTrip = (e) => {
    setFieldData1("");
    setFieldData2("");
    setFieldData3("");
    setFieldData4("");

    if (sidebarVisible) {
      setSidebarVisible(false);
    }
    setIsAddNewTrip(true);
    if (chatUserName === "") {
      setChatUserName("Message 1");
    }
  };

  const handleConversationClick = useCallback((item) => {
    setConversationID(item?.conversation?.id);
    if (sidebarVisible) {
      setSidebarVisible(false);
    }
    if (item?.users[0]?.first_name !== "") {
      const userName =
        item?.users[0]?.first_name ||
        "" + " " + item?.users[0]?.last_name ||
        "";
      setChatUserName(userName);
      setIsAddNewTrip(false);
      // if (conversationId === item?.conversation?.id) {
      //   setConversationId("");
      // } else {
      //   setConversationId(item?.conversation?.id);
      // }
    }
  }, []);

  useEffect(() => {
    listChat();
  }, [status]);

  const listChat = () => {
    setLoader(true);
    if (!Boolean(slug) && driver?.id) {
      setDriverId(driver?.id);
    }
    axios
      .get(`offers/mine?offer_status=${status}`)
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          setChatList(res?.data?.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          alert(
            Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message
          );
        }
      });
  };
  useEffect(() => {
    handleCurrentTrip();
  }, []);

  const handleCurrentTrip = () => {
    // setLoader(true);
    axios
      .get("trips/current")
      .then((res) => {
        if (res?.data?.success) {
          // setLoader(false);
          setCurrentTrip(res?.data?.data);
          setTripId(res?.data?.data?.id);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          alert(
            Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message
          );
        }
      });
  };

  useEffect(() => {
    if (slug) {
      handleConversation();
      chatList?.map((chat) => {
        if (chat?.id === +slug) {
          setConversationID(chat?.conversation?.id);
        }
      });
    }
  }, [slug]);

  const handleConversation = () => {
    setConversationLoader(true);
    axios
      .get("offers/" + slug + "/conversation")
      .then((res) => {
        if (res?.data?.success) {
          setConversationLoader(false);
          setConversation(res?.data?.data);
          if (Object.keys(res?.data?.data?.conversation)?.length > 0) {
            setTripLocation(res?.data?.data?.conversation?.name);
          }
          if (res?.data?.data?.users?.length > 0) {
            const userName =
              res?.data?.data?.users[0]?.first_name +
              " " +
              res?.data?.data?.users[0]?.last_name;
            setChatUserName(userName);
            setDriverProfile(res?.data?.data?.users[0]?.small_image_url);
            setDriverId(res?.data?.data?.users[0]?.id);
            console.log("conversation", res?.data?.data);
            if (newTrip) {
              setNewTrip(false);
              var new_list = chatList;
              let index = new_list.findIndex(
                (chat) => chat?.id === res?.data?.data?.id
              );
              if (index === -1) {
                setChatList([res?.data?.data, ...chatList]);
              } else {
                setChatList([
                  ...chatList?.map((chat) => {
                    if (chat?.id === res?.data?.data?.id) {
                      return res?.data?.data;
                    } else {
                      return chat;
                    }
                  }),
                ]);
              }
              // setChatList([res?.data?.data, ...chatList]);
            }
            console.log("Message List:::", res?.data?.data?.messages);
            setMessages(res?.data?.data?.messages);
          }
        }
      })
      .catch((err) => {
        setConversationLoader(false);
        if (err?.response?.data?.message) {
          alert(
            Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message
          );
        }
      });
  };

  const sendMessage = (message) => {
    axios
      .post("messages/store", {
        conversation_id: conversationID,
        message: message.replaceAll("&nbsp;", ""),
        receiver_id: driver?.id,
        sender_id: user?.id,
      })
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            console.log("SEND::", res?.data?.data);
            let list = [...chatList].map((e) => {
              if (e?.conversation?.id === res?.data?.data?.conversation_id) {
                e.conversation.last_message = res?.data?.data.message;
                e.status = res?.data?.data.offer.status;
              }
              return e;
            });
            setChatList(list);
            if (res?.data?.data?.offer?.id === +slug);
            setMessages([...messages, res?.data?.data]);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          alert(
            Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message
          );
        }
      });
  };

  useEffect(() => {
    console.log("LIST:: ", chatList);
  }, [chatList]);

  useEffect(() => {
    // Pusher.logToConsole = true;
    let authPoint = `${process.env.REACT_APP_API_ROOT_URL}broadcasting/auth`;
    const pusher = new Pusher("0e8dbcbdf03d5f6c9ff6", {
      cluster: "ap2",
      authEndpoint: authPoint,
      auth: {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      },
    });

    const channel = pusher.subscribe("private-chat." + user?.id);
    channel.bind("chat-event", function (data) {
      console.log("RECEIVE::", data);
      setReceiveMsg(data?.message);
    });
  }, []);

  useEffect(() => {
    if (receiveMsg) {
      setMessages((_) => [..._, receiveMsg]);
      redirectUser(receiveMsg?.offer?.status);
      let list = [...chatList].map((e) => {
        if (e.id === receiveMsg?.offer?.id) {
          return { ...e, ...receiveMsg?.offer };
        }
        return e;
      });
      setChatList(list);
    }
  }, [receiveMsg]);

  const currentOffer = useMemo(() => {
    return chatList.find((e) => e.id === +slug) || {};
  }, [slug, chatList]);

  const dateSeparator = (i, cur, prev) => {
    if (i === 0 || !moment(cur?.created_at).isSame(prev?.created_at, "day")) {
      const currentDate = moment(cur?.created_at).format("DD/MM/YYYY");
      const today = moment().format("DD/MM/YYYY");
      const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
      return today === currentDate
        ? "Today"
        : yesterday === currentDate
        ? "Yesterday"
        : currentDate;
    }
    return "";
  };
  const inlineDate = (i, cur, prev) => {
    const curTime = moment(cur?.created_at).format("hh:mm A");
    const prevTime = moment(prev?.created_at).format("hh:mm A");
    if (i === 0 || curTime !== prevTime || cur?.sender_id !== prev?.sender_id)
      return curTime;
    return "";
  };
  const offerTime = (data) => {
    const currentDate = moment(data?.created_at).format("DD/MM/YYYY");
    const today = moment().format("DD/MM/YYYY");
    return currentDate === today
      ? moment(data?.created_at).format("hh:mm A")
      : currentDate;
  };

  const redirectUser = (status) => {
    if (
      [
        "acceptedByDriver",
        "acceptedByUser",
        "locationShared",
        "chatInitiated",
      ].includes(status)
    ) {
      props.history.push(`/offer/accepted/${slug}`);
    } else if (
      [
        "declinedByDriver",
        "declinedByUser",
        "cancelledByDriver",
        "cancelledByUser",
        "expired",
      ].includes(status)
    ) {
      props.history.push(`/offer/expired/${slug}`);
    } else if (["endedByUser", "endedByDriver", "completed"].includes(status)) {
      props.history.push(`/offer/completed/${slug}`);
    } else if (["counteredByUser", "counteredByDriver"].includes(status)) {
      props.history.push(`/offer/new/${slug}`);
    }
  };

  const makeOffer = (action, value) => {
    if (cancelLoader) return;
    setCancelLoader(action);
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("access_token");
    axios
      .post(`offers/make/${slug}`, { action, counter_amount: value })
      .then((res) => {
        setMessages([...res.data.data.messages]);
        let list = [...chatList].map((e) => {
          if (e.id === res.data.data.id) {
            delete res.data.data.messages;
            return { ...e, ...res.data.data };
          }
          return e;
        });
        setChatList(list);
        redirectUser(action);
        setOfferCountFlag(false);
        setCancelLoader("");
      })
      .catch((err) => {
        setCancelLoader("");
        setOfferCountFlag(false);
        if (err?.response?.data?.message) {
          alert(
            Array.isArray(err?.response?.data?.message)
              ? err?.response?.data?.message[0]
              : err?.response?.data?.message
          );
        }
      });
  };

  const handleUserStatus = (value) => {
    // console.log("online", value);
    setUserIsOnline(!userIsOnline);
    axios
      .post("user/online/status", { is_online: value })
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            const user = res?.data?.data;
            setUserIsOnline(Boolean(user?.is_online));
            localStorage.setItem("user", JSON.stringify(user));
          }
        }
      })
      .catch((err) => {
        setUserIsOnline(!value);
        if (err?.response) {
          if (err?.response?.data?.message) {
            alert(
              Array.isArray(err?.response?.data?.message)
                ? err?.response?.data?.message[0]
                : err?.response?.data?.message
            );
          }
        } else {
          alert("Couldn't connect to server");
        }
      });
  };

  const handleSmsStatus = (value) => {
    setSmsStatus(!smsStatus);
    axios
      .post("user/sms/status", { sms_subscribed: value })
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            const user = res?.data?.data;
            //setSmsStatus(Boolean(user?.sms_subscribed));
            localStorage.setItem("user", JSON.stringify(user));
          }
        }
      })
      .catch((err) => {
        setSmsStatus(!value);
        if (err?.response) {
          if (err?.response?.data?.message) {
            alert(
              Array.isArray(err?.response?.data?.message)
                ? err?.response?.data?.message[0]
                : err?.response?.data?.message
            );
          }
        } else {
          alert("Couldn't connect to server");
        }
      });
  };

  console.log("messages", messages);
  const setCurrentScreenMsg = (data, message) => {
    if (data?.conversation_id === conversationID) {
      const { offer, ...newMessage } = data;
      setMessages([...messages, newMessage]);
    }
  };

  return (
    <div className="multi_chat_container">
      <MainContainer
        // className={detectMob() && !sidebarVisible ? "swap_screen" : ""}
        className={conversationID || !sidebarVisible ? "swap_screen" : ""} // change here for slug change swap screen 25-07
        responsive
      >
        <Sidebar position="left" scrollable={false} style={sidebarStyle}>
          <ConversationHeader>
            <Avatar src={profilePic} size="md" />
            <ConversationHeader.Content>
              <h5>{loginUser}</h5>
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              <div>
                <UiButton
                  onClick={(e) => logOut(e)}
                  icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                />
              </div>
            </ConversationHeader.Actions>
          </ConversationHeader>
          <ConversationList>
            {/* <div className="p-2">  ------------ comment on 25-07
              {Boolean(currentTrip) &&
                Object.keys(currentTrip)?.length > 0 &&
                currentTrip?.location_name}
            </div> */}
            <div className="d-flex justify-content-between align-items-center px-2 pt-1 pb-1">
              <div className="form-check form-switch ">
                <input
                  className="form-check-input  "
                  type="checkbox"
                  id="isOnline"
                  name="isOnline"
                  //   value={values.sms}
                  checked={userIsOnline}
                  onChange={(e) => {
                    handleUserStatus(e.target.checked);
                  }}
                />
                <label htmlFor="isOnline">
                  {user?.is_online === 1 ? "Online" : "Offline"}
                </label>
              </div>
              <div className="form-check form-switch ms-2">
                <input
                  className="form-check-input  "
                  type="checkbox"
                  id="sms"
                  name="sms"
                  // value={smsStatus}
                  checked={smsStatus}
                  onChange={(e) => {
                    handleSmsStatus(e.target.checked);
                  }}
                />
                <label htmlFor="sms">SMS Update</label>
              </div>
            </div>
            <div className="tab-div">
              {tabList.map((tab) => {
                return (
                  <span
                    key={tab.value}
                    onClick={() => {
                      if (tab.value !== status) {
                        //   props.history.push(`/offer/${tab.value}`);
                        const toLocation = {
                          pathname: `/offer/${tab.value}`,
                          state: { from: { pathname: location.pathname } },
                        };
                        history.push(toLocation);
                      }
                    }}
                    className={`tab-item ${
                      tab.value === status ? "active" : ""
                    }`}
                  >
                    {tab.label}
                  </span>
                );
              })}
            </div>
            {loader ? (
              <div className="d-flex justify-content-center mt-2">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : chatList?.length > 0 ? (
              chatList.map((item, index) => {
                return (
                  <Link
                    // to={`/offer/${status}/${item?.id}`}
                    to={{
                      pathname: `/offer/${status}/${item?.id}`,
                      state: { from: { pathname: location.pathname } },
                    }}
                    key={index}
                    style={{ textDecoration: "none" }}
                  >
                    <Conversation
                      active={+slug === item?.id}
                      key={index}
                      onClick={() => {
                        handleConversationClick(item);
                      }}
                      // lastActivityTime={offerTime(item)}
                      name={
                        <>
                          {item?.users?.length > 0 && (
                            <b className="username">
                              {item?.users[0]?.first_name +
                                " " +
                                item?.users[0]?.last_name}
                            </b>
                          )}
                          <div
                            style={{ wordBreak: "break-word", display: "flex" }}
                          >
                            <b className="location">
                              {item?.conversation?.name}
                            </b>
                          </div>
                        </>
                      }
                      info={
                        <>
                          <div>{item?.conversation?.last_message}</div>
                          <UiButton className="chatInButton mt-1" border>
                            {OFFER_STATUS[item?.status]}
                          </UiButton>
                        </>
                      }
                    >
                      <Avatar
                        src={item?.users[0]?.small_image_url || Car}
                        name={item?.status}
                        status={item?.users[0]?.is_online ? "available" : "dnd"}
                        style={conversationAvatarStyle}
                      />
                      <Conversation.Content
                        className="message_list"
                        style={conversationContentStyle}
                      >
                        {/* <div>{item?.conversation?.last_message}</div> */}
                        <UiButton className="chatInButton" border>
                          Offer Made
                        </UiButton>
                      </Conversation.Content>
                    </Conversation>
                  </Link>
                );
              })
            ) : (
              <div className="d-flex justify-content-center">
                No offer found
              </div>
            )}
          </ConversationList>
          {/* <div className="submit-btn">
            <Button
              type="button"
              variant="dark"
              onClick={(event) => addNewTrip(event)}
              style={{ fontSize: "25px" }}
            >
              <div className="custom_button">New Trip</div>
              <div className="custom_text">NT</div>
            </Button>
          </div> */}
          <div className="p-2">
            <button
              className="btn btn-dark shadow-none fw-400 button mt-2 w-100"
              type="button"
              onClick={(event) => addNewTrip(event)}
            >
              <span>New Trip</span>
            </button>
          </div>
        </Sidebar>
        {isAddNewTrip ? (
          <CreateTrip
            setChatList={setChatList}
            setCurrentTrip={setCurrentTrip}
            tripId={tripId}
            driverId={driverId}
            setTripId={setTripId}
            setIsAddNewTrip={setIsAddNewTrip}
            chatContainerStyle={chatContainerStyle}
            handleBackClick={handleBackClick}
            loginUser={loginUser}
            logOut={logOut}
            listChat={listChat}
            handleCurrentTrip={handleCurrentTrip}
            setNewTrip={setNewTrip}
            driver={driver}
            setCurrentScreenMsg={setCurrentScreenMsg}
          />
        ) : Boolean(slug) ? (
          <div className={`chat-container ${status === "new" && "new-status"}`}>
            <ChatContainer style={chatContainerStyle}>
              <ConversationHeader>
                <ConversationHeader.Back onClick={handleBackClick} />
                <Avatar src={driverProfile} name="Zoe" />
                <ConversationHeader.Content
                  userName={
                    <>
                      {chatUserName}
                      <div style={{ wordBreak: "break-word", display: "flex" }}>
                        <b className="location">{tripLocation}</b>
                      </div>
                    </>
                  }
                />
                {/* <ConversationHeader.Content tripLocation={tripLocation} /> */}
              </ConversationHeader>
              <MessageList>
                {status === "new" && (
                  <div className={"new-offer-div"}>
                    <Button
                      className={"pe-none disabled btn-sm mb-2"}
                      variant={"dark"}
                    >
                      Waiting for{" "}
                      {currentOffer?.status !== "counteredByDriver"
                        ? `${chatUserName}'s`
                        : "your"}{" "}
                      response...
                    </Button>
                    <h4>
                      Your Offer Amount - ${currentOffer?.amount?.toFixed(2)}
                    </h4>
                    <h5>
                      Once {chatUserName} accepts your offer, you can chat.
                    </h5>
                    <div className={"d-flex justify-content-center"}>
                      {(currentOffer?.status === "declinedByDriver" ||
                        currentOffer?.status === "counteredByDriver") &&
                        currentOffer?.offer_counter?.[
                          currentOffer?.offer_counter?.length - 1
                        ]?.counter_amount && (
                          <>
                            <button
                              className="btn btn-success shadow-none fw-500 btn-sm counter-btn mr-5"
                              disabled={!!cancelLoader}
                              onClick={() => makeOffer("acceptedByUser")}
                            >
                              <span>
                                Accept for $
                                {
                                  currentOffer?.offer_counter?.[
                                    currentOffer?.offer_counter?.length - 1
                                  ]?.counter_amount
                                }
                              </span>
                              {cancelLoader === "acceptedByUser" && (
                                <span
                                  className="spinner-border spinner-border-sm ms-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                            <button
                              className="btn btn-dark shadow-none fw-500 btn-sm counter-btn mr-5"
                              disabled={!!cancelLoader}
                              onClick={() => setOfferCountFlag(!offerCountFlag)}
                            >
                              <span>Custom Amount</span>
                              {cancelLoader === "counteredByUser" && (
                                <span
                                  className="spinner-border spinner-border-sm ms-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                          </>
                        )}
                      <button
                        className="btn btn-danger shadow-none fw-500 counter-btn"
                        disabled={!!cancelLoader}
                        onClick={() => makeOffer("cancelledByUser")}
                      >
                        <span>Cancel</span>
                        {cancelLoader === "cancelledByUser" && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>

                    {offerCountFlag && (
                      <div className="d-flex mt-2 align-items-center">
                        <input
                          type="number"
                          autoComplete={"off"}
                          className="form-control border-dark input mb-2 mr-1"
                          placeholder="Offer counter amount"
                          id="offer_amount"
                          name="offer_amount"
                          disabled={!!cancelLoader}
                          value={offer_amount}
                          onChange={(e) =>
                            setOffer_amount(e.target.valueAsNumber)
                          }
                        />
                        <SendButton
                          disabled={!offer_amount || !!cancelLoader}
                          onClick={() =>
                            makeOffer("counteredByUser", offer_amount)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}

                {status === "accepted" && (
                  <div className={"new-offer-div"}>
                    <div className={"d-flex justify-content-center"}>
                      {currentOffer?.status === "chatInitiated" && (
                        <>
                          <button
                            className="btn btn-dark shadow-none fw-500 btn-sm counter-btn mr-5"
                            disabled={!!cancelLoader}
                            onClick={() => setOfferCountFlag(!offerCountFlag)}
                          >
                            <span>Custom Amount</span>
                            {cancelLoader === "counteredByUser" && (
                              <span
                                className="spinner-border spinner-border-sm ms-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </button>
                        </>
                      )}

                      <button
                        className="btn btn-danger shadow-none fw-500 counter-btn"
                        disabled={!!cancelLoader}
                        onClick={() => makeOffer("cancelledByUser")}
                      >
                        <span>Cancel</span>
                        {cancelLoader === "cancelledByUser" && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>

                    {offerCountFlag && (
                      <div className="d-flex mt-2 align-items-center">
                        <input
                          type="number"
                          autoComplete={"off"}
                          className="form-control border-dark input mb-2 mr-1"
                          placeholder="Offer counter amount"
                          id="offer_amount"
                          name="offer_amount"
                          disabled={!!cancelLoader}
                          value={offer_amount}
                          onChange={(e) =>
                            setOffer_amount(e.target.valueAsNumber)
                          }
                        />
                        <SendButton
                          disabled={!offer_amount || !!cancelLoader}
                          onClick={() =>
                            makeOffer("counteredByUser", offer_amount)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
                {/* <div className={"new-offer-div"}>
                  <div className={"d-flex justify-content-center"}>
                    {status === "accepted" &&
                      currentOffer?.status === "chatInitiated" && (
                        <button
                          className="btn btn-dark shadow-none fw-500 btn-sm counter-btn mr-5"
                          disabled={!!cancelLoader}
                          onClick={() => setOfferCountFlag(!offerCountFlag)}
                        >
                          <span>Custom Amount</span>
                          {cancelLoader === "counteredByUser" && (
                            <span
                              className="spinner-border spinner-border-sm ms-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      )}
                    {status === "accepted" && (
                      <div className={"accept-cancel-btn"}>
                        <button
                          className="btn btn-danger shadow-none fw-500 counter-btn"
                          disabled={!!cancelLoader}
                          onClick={() => makeOffer("cancelledByUser")}
                        >
                          <span>Cancel</span>
                          {cancelLoader === "cancelledByUser" && (
                            <span
                              className="spinner-border spinner-border-sm ms-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div> */}

                <div className={"message-container"}>
                  {conversationLoader ? (
                    <div className="d-flex justify-content-center mt-2">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    Array.isArray(messages) &&
                    messages?.length > 0 &&
                    messages.map((data, index, arr) => (
                      <>
                        {dateSeparator(index, data, arr[index - 1]) && (
                          <MessageSeparator
                            content={dateSeparator(index, data, arr[index - 1])}
                          />
                        )}
                        <Message
                          key={index}
                          model={{
                            message: data?.message,
                            sentTime: data?.created_at,
                            sender: "Zoe",
                            direction:
                              data?.sender_id === user.id
                                ? "outgoing"
                                : "incoming",
                            position: "single",
                          }}
                        >
                          {inlineDate(index, data, arr[index - 1]) && (
                            <Message.Footer
                              className={"message-time"}
                              sentTime={inlineDate(index, data, arr[index - 1])}
                            />
                          )}
                        </Message>
                      </>
                    ))
                  )}
                </div>
              </MessageList>
              {status === "accepted" && (
                <MessageInput
                  placeholder="Type message here"
                  attachButton={false}
                  onSend={(textContent) => sendMessage(textContent)}
                />
              )}
            </ChatContainer>
          </div>
        ) : (
          !detectMob() && (
            <ChatContainer style={chatContainerStyle}>
              <MessageList>
                <MessageList.Content
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faMessage} color="#bfccdf" />
                  <p className="pick-person">
                    Pick a person from left menu, <br />
                    and start your conversation.
                  </p>
                </MessageList.Content>
              </MessageList>
            </ChatContainer>
          )
        )}
      </MainContainer>
    </div>
  );
}

export default withRouter(ChatDesktop);
